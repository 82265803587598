










import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";
import MoneyInputItemType from "./indexType";

@Component({name: "MoneyInputItem"})
export default class MoneyInputItem extends Vue implements MoneyInputItemType{
    content = ""

    @Prop(String)
    title!:string
    get getTitle(){ return this.title || '默认标题' }

    @Prop(String)
    place!:string
    get getPlace(){ return this.place || '请输入内容' }

    @Prop(String)
    type!:string
    get getType(){ return this.type || 'text' }

    @Prop(Boolean)
    disabled!:boolean
    get getDisabled(){ return this.disabled || false }

    @Prop(Number)
    border!:number
    get getBorder(){ return this.border || 1 }

    @Prop(String)
    backColor!:string
    get getBackColor(){ return this.backColor || '#fff' }

    @Prop(Object)
    other!:any
    get getOther(){ return this.other || { } }

    @Prop(String)
    value!:string
    @Watch("value")
    changeValue(newVal:any){
        this.content = newVal
    }
    @Watch("content")
    changeContent(newVal:any){
        this.pullData( newVal )
    }
    @Emit("pullData")
    pullData(data:any){
        return {
            other:this.getOther,
            data
        }
    }
}
