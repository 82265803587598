










import {Vue, Component, Prop} from "vue-property-decorator";
import AccountItemType from "./indexType";

@Component({name: "AccountItem"})
export default class AccountItem extends Vue implements AccountItemType{

    @Prop(String)
    title!:string
    get getTitle(){ return this.title }

    @Prop(Number)
    border!:number
    get getBorder(){ return this.border || 1 }

    @Prop(Number)
    height!:number
    get getHeight(){ return this.height || 40 }
}
