





































import {Vue, Component} from "vue-property-decorator";
import AddAccountType from "./AddAccount";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ZoomPage from "../../../impView/PageSafety";
import MoneyInputItem from "@/views/Money/components/InputItem/index.vue";
import { keyArr, defaultList } from './Model/index';
import {setAddAccount, updateAccount} from "@/views/Money/AddAccount/Server";
import { typeList } from "./Model/index";
import {filterList} from "@/views/Money/AddAccount/util";
import AccountItem from "@/views/Money/AddAccount/components/Item/index.vue";

@Component({name: "AddAccount",components:{ HeadTop,MoneyInputItem,AccountItem }})
export default class AddAccount extends ZoomPage implements AddAccountType{
    typeList = typeList
    defaultList = defaultList
    account = ""
    name = ""
    ident = ""
    phone = ""
    message = ""
    oldData:any = {}
    updateState = false

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let { state = "",data = "" } = this.$route.query
        if ( state && state === "update" ){
            try{
                this.oldData = JSON.parse(data as string) || {}
            }catch (e) { console.info(e)    }
            this.initData()
            this.updateState = true
        }else{
            this.init()
        }
    }

    init(){
        this.account = ""
        this.name = ""
        this.ident = ""
        this.phone = ""
        this.message = ""
        this.typeList = typeList
        this.defaultList = defaultList
        this.updateState = false
    }

    initData(){
        let {
            account = "",
            name = "",
            idCard = "",
            phone = "",
            remark = "",
            defaultType = 1,
            accountType = 1 // 1 微信 2 支付宝
        } = this.oldData
        this.account = account
        this.name = name
        this.ident = idCard
        this.phone = phone
        this.message = remark
        this.defaultList = this.defaultList.map((item,index)=>{
            if (defaultType == 1){
                if ( index )item.active = true;
                else item.active = false;
            }else{
                if ( index )item.active = false;
                else item.active = true;
            }
            return item
        })
        this.typeList = this.typeList.map((item,index)=>{
            if ( accountType == 1 ){
                if ( index )item.active = false;
                else item.active = true;
            }else{
                if ( index )item.active = true;
                else item.active = false;
            }
            return item
        })
    }

    handleClickType(state=0){
        this.typeList = filterList(this.typeList,state)
    }

    handleClickDefault(state=0){
        this.defaultList = filterList(this.defaultList,state)
    }

    handleGetData(e:any){
        keyArr.forEach((item,index)=>{
            if ( e.other.state == item ){
                this[item] = e.data
            }
        })
    }

    handleClickBtn(){
        let { state = "" } = this.$route.query
        if ( state != "update" ){
            setAddAccount(this).then(res=>{
                this.$router.go(-1)
            }).catch(cat=>cat)
        }else{
            updateAccount(this,this.oldData).then(res=>{
                this.$router.go(-1)
            }).catch(cat=>cat)
        }
    }
}
